var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-blacker-layout',{attrs:{"column":""}},[_c('v-blacker-content-box',{attrs:{"title":"Statistics"}},[_c('v-blacker-date-picker',{staticClass:"custom-header-datepicker",attrs:{"slot":"title-right","clearable":false,"min":_vm.allowedDatesRange.min,"max":_vm.allowedDatesRange.max,"confirm":"","range":""},slot:"title-right",model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}}),_c('v-blacker-layout',{style:({ height: _vm.statLoading ? 'auto' : '370px' })},[_c('v-blacker-flex',{attrs:{"lg7":""}},[(_vm.statLoading)?_c('v-blacker-loading-spinner'):_c('v-blacker-layout',{attrs:{"column":"","align-center":""}},[_c('strong',{staticClass:"white--text",staticStyle:{"margin":"15px 0 -35px 0"}},[_vm._v(" Orders count by customers ")]),_c('ve-histogram',{attrs:{"data":_vm.chartData,"extend":_vm.chartExtend,"legend-visible":false,"settings":_vm.chartSettings,"text-style":{ color: '#fff' },"height":"420px","width":"100%"}})],1)],1),_c('v-blacker-flex',{attrs:{"lg5":""}},[(_vm.statLoading)?_c('v-blacker-loading-spinner'):_c('v-blacker-layout',{attrs:{"column":"","align-center":""}},[_c('strong',{staticClass:"white--text",staticStyle:{"margin":"15px 0 -35px 0"}},[_vm._v(" Total orders count by customers ")]),_c('ve-pie',{staticStyle:{"margin-top":"20px"},attrs:{"data":_vm.totalChartData,"legend-visible":false,"text-style":{ color: _vm.$vuetify.theme.dark ? '#fff' : '#000' },"width":"100%"}})],1)],1)],1)],1),_c('v-blacker-content-box',{attrs:{"title":"Alerts"}},[(_vm.alertsLoading)?_c('v-blacker-loading-spinner'):(_vm.alerts.length)?_c('v-blacker-table',{attrs:{"headers":_vm.alertsListHeaders,"items":_vm.alerts},scopedSlots:_vm._u([{key:"orderId",fn:function(ref){
var id = ref.item.orderId;
return [(id)?_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
              name: 'order',
              params: { id: id },
            }}},[_vm._v(" "+_vm._s(id)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"timestamp",fn:function(ref){
            var timestamp = ref.item.timestamp;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(timestamp,'local', '-'))+" ")]}}])}):_c('v-blacker-layout',{staticStyle:{"height":"100%"},attrs:{"align-center":"","justify-center":""}},[_vm._v(" No alerts ")]),(!_vm.alertsLoading && _vm.alerts.length)?_c('v-blacker-layout',{attrs:{"justify-end":""}},[_c('v-blacker-button',{staticClass:"vbt-margin-t-15",attrs:{"to":{ name: 'alerts', query: { pageNum: '1' } },"small":"","outlined":"","color":"info"}},[_vm._v(" Show more alerts ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }