import { mapState, mapActions, mapGetters } from 'vuex';

import { format } from 'date-fns';

const alertsListHeaders = Object.freeze([
  { text: 'Order ID', value: 'orderId' },
  { text: 'Timestamp', value: 'timestamp' },
  { text: 'Error', value: 'errorType' },
  { text: 'Comments', value: 'comments' },
]);

const onTooltipChange = (tooltipAxisData) => {
  const sorted = tooltipAxisData.sort(({ value: a = 0 }, { value: b = 0 }) => b - a);
  const tooltip = document.createElement('div');
  tooltip.style.padding = '5px';

  sorted.forEach((v) => {
    tooltip.innerHTML = `${tooltip.innerHTML}<div>${v.marker}${v.seriesName}: ${v.value || '-'}</div>`;
  });

  return tooltip.outerHTML;
};

export default {
  name: 'Dashboard',
  data() {
    return {
      alertsListHeaders,
      dates: this.getInitialDates(),
      allowedDatesRange: this.getAllowedDatesRange(),
      chartSettings: {
        metrics: [],
        stack: { orders: [] },
      },
      statLoading: false,
      alertsLoading: false,
    };
  },
  computed: {
    ...mapGetters('accounts', ['accountNameById']),
    ...mapState('orders', ['statistics']),
    ...mapState('alerts', ['alerts']),
    chartData() {
      return this.generateChartData();
    },
    totalChartData() {
      return {
        columns: ['label', 'count'],
        rows: this.generateTotalChartData(),
      };
    },
    chartExtend() {
      return {
        tooltip: {
          show: true,
          renderMode: 'html',
          formatter: onTooltipChange,
        },
      };
    },
  },
  watch: {
    dates: {
      handler(dates) {
        this.getStat(dates);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getLastAlerts();
    this.getAccounts();
  },
  methods: {
    ...mapActions('orders', ['getOrdersStatistics']),
    ...mapActions('accounts', ['getAccounts']),
    ...mapActions('alerts', ['getAlerts']),
    generateChartData() {
      const { orderCounts = [] } = this.statistics;
      const accountsEntries = {};
      const rows = orderCounts
        .reduce((acc, order) => {
          const account = this.accountNameById[order.printCustomerId];
          let includedOrder = acc.find(({ label }) => label === order.label);
          if (!includedOrder) {
            acc.push({
              label: order.label,
              accounts: [],
            });
            includedOrder = acc[acc.length - 1];
          }
          const includedAccount = includedOrder.accounts
            .find(({ accountName }) => accountName === account);
          if (!includedAccount) {
            includedOrder.accounts.push({ accountName: account, count: order.count });
          } else {
            includedAccount.count += order.count;
          }
          includedOrder[account] = includedAccount ? includedAccount.count : order.count;
          return acc;
        }, [])
        .map((order) => {
          order.accounts.forEach(({ accountName }) => {
            accountsEntries[accountName] = accountsEntries[accountName]
              ? accountsEntries[accountName] + 1
              : 1;
          });
          return order;
        });
      const accountsNames = Object.entries(accountsEntries).map(([name]) => name);
      const columns = ['label', ...accountsNames];
      Object.assign(this, {
        chartSettings: {
          metrics: accountsNames,
          stack: { orders: accountsNames },
        },
      });
      return { rows, columns };
    },
    generateTotalChartData() {
      const { orderCounts = [] } = this.statistics;

      const stat = orderCounts.reduce((acc, item) => {
        const customer = this.accountNameById[item.printCustomerId];
        const label = acc.find(l => l.label === customer);
        const l = label || { label: customer, count: 0 };
        l.count += item.count;
        if (!label) {
          acc.push(l);
        }
        return acc;
      }, []);

      stat.sort(({ count: a }, { count: b }) => {
        if (a < b) {
          return 1;
        }
        if (a > b) {
          return -1;
        }
        return 0;
      });

      const range = stat.slice(0, 10);

      const other = stat.slice(10).reduce((acc, item) => {
        acc.count += item.count;
        return acc;
      }, { label: 'Other', count: 0 });

      if (other.count) {
        range.push(other);
      }

      return range;
    },
    getAllowedDatesRange() {
      const date = new Date();
      const nextDay = date.setDate(date.getDate() + 1);
      return {
        min: `${date.getFullYear() - 2}-01-01`,
        max: (new Date(nextDay)).toISOString(),
      };
    },
    getInitialDates() {
      const today = new Date();
      const yesterday = new Date(today.setDate(today.getDate() - 7));
      return [
        format(yesterday, 'yyyy-MM-dd'),
        format(new Date(), 'yyyy-MM-dd'),
      ];
    },
    async getStat(dates) {
      this.statLoading = true;
      try {
        await this.getOrdersStatistics({
          startDate: dates[0],
          endDate: dates[1],
        });
      } finally {
        this.statLoading = false;
      }
    },
    async getLastAlerts() {
      this.alertsLoading = true;
      try {
        await this.getAlerts({ pageSize: 5 });
      } finally {
        this.alertsLoading = false;
      }
    },
  },
};
